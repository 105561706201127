export class ScrollToTop {
  constructor(selector = '.up', options = {}) {
    this.defaultOptions = {
      duration: 400,
      easing: this.easing,
      to: 0
    };
    this.options = Object.assign(this.defaultOptions, options);
    this.el = document.querySelector(selector);

    this.setOpacity();
    window.addEventListener('scroll', () => this.setOpacity());

    this.el.addEventListener('click', () => {
      (!window.requestAnimationFrame) ? window.scrollTo(0, 0): this.animatedScrollTo(this.options);
    });
  }

  setOpacity() {
    let scroll = window.pageYOffset;
    let windowHeight = window.innerHeight;
    let half = windowHeight / 2;

    let opacity = (scroll - half) / windowHeight;
    opacity = Number(opacity.toFixed(2));
    opacity = Math.max(0, opacity);

    if (opacity > 1) {
      opacity = 1;
    }

    this.el.style.opacity = opacity;
  }

  animatedScrollTo(options) {
    options.startingYOffset = window.pageYOffset;
    options.distanceYOffset = parseInt(options.to, 10) - options.startingYOffset;
    window.requestAnimationFrame((timestamp) => this.animateScroll(options, timestamp));
  }

  animateScroll(options, now) {
    if (!options.startTime) {
      options.startTime = now;
    }
    const currentTime = now - options.startTime;
    let newYOffset = Math.round(options.easing(currentTime, options.startingYOffset, options.distanceYOffset, options.duration));
    if (currentTime < options.duration) {
      window.requestAnimationFrame((timestamp) => this.animateScroll(options, timestamp));
    }
    else {
      newYOffset = options.to;
    }
    this.setScrollTopPosition(newYOffset);
  }

  setScrollTopPosition(newYOffset) {
    document.documentElement.scrollTop = newYOffset;
    document.body.scrollTop = newYOffset;
  }

  // change easing functions, see https://github.com/janrembold/es6-easings
  // import {easeInQuint} from 'es6-easings';
  // Defaults: easeOutQuad
  easing(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  }

}

export class ToggleMenu {
  constructor(options = {}) {
    this.defaultOptions = {
      toggleClass: 'active',
      menuSelector: '.main-menu',
      headerSelector: '.header',
      toggleSelector: '[data-toggle=menu]',
      // breakpoint: 767,
      breakpoint: 1023,
    };
    this.options = Object.assign(this.defaultOptions, options);

    this.menu = document.querySelector(this.options.menuSelector);
    this.header = document.querySelector(this.options.headerSelector);
    this.hamburger = document.querySelector(this.options.toggleSelector);

    this.hamburger.addEventListener('click', () => this.toggle());
    window.addEventListener('resize', () => this.changeMenuHeight());
  }

  toggle() {
    this.hamburger.classList.toggle(this.options.toggleClass);

    this.changeMenuHeight();
  }

  changeMenuHeight() {
    if (window.innerWidth < this.options.breakpoint) {
      if (this.hamburger.classList.contains(this.options.toggleClass)) {
        let headerHeight = this.header.offsetHeight;
        let windowHeight = window.innerHeight;
        let diff = windowHeight - headerHeight;

        this.menu.style.height = `${diff}px`;
      } else {
        this.menu.style.height = `0px`;
      }
    } else {
      this.menu.removeAttribute('style');
    }
  }

}

import {StickyHeader} from "../classes/StickyHeader";
import {ToggleMenu} from "../classes/ToggleMenu";
import {ScrollToTop} from "../classes/ScrollToTop";
import Rellax from 'rellax';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    new Rellax('.rellax', {
      // wrapper:'.rel',
    });

    new ScrollToTop('.up');

    new StickyHeader('.header', {
      wrapper: false,
    });

    new ToggleMenu({
      menuSelector: '.header__menu',
    });
  },
};

export class StickyHeader {
  constructor(selector, options = {}) {
    this.defaultOptions = {
      onTop: false,
      wrapper: 'span',
      class: 'sticky-header',
    };
    this.options = Object.assign(this.defaultOptions, options);

    this.el = document.querySelector(selector);

    if (this.options.wrapper) {
      this.wrapElement();
    }

    this.setEvents();
  }

  setEvents() {
    this.setParentHeight();
    window.addEventListener('resize', () => this.setParentHeight());

    let height = this.el.offsetHeight;
    let currentScroll = window.pageYOffset;
    if (currentScroll > height) {
      this.el.classList.add(this.options.class);
    }

    window.addEventListener("scroll", () => this.scroll());
  }

  wrapElement() {
    let wrapper = document.createElement(this.options.wrapper);
    this.el.parentNode.insertBefore(wrapper, this.el);
    wrapper.appendChild(this.el);
  }

  setParentHeight() {
    let height = this.el.offsetHeight;
    this.el.parentElement.style.height = `${height}px`;
  }

  scroll() {
    let height = this.el.offsetHeight;
    let currentScroll = window.pageYOffset;

    if (currentScroll > height) {
      this.el.classList.add(this.options.class);
    } else {
      this.el.classList.remove(this.options.class);
    }
  }
}
